.cintilla {
    margin-top: 30px;
}

.bg__black {
    background-color: black;
}

.bg__transparent {
    background-color: transparent;
}

.text__black {
    color: black;
}

.text__white {
    color: white;
}

.desktop__nav {
    display: none;

    @media (min-width: 768px) {
        flex: 1;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        .link__container {
            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                list-style: none;
                margin: 0;
            }

            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }
}


.mobile__nav {
    position: relative;
    z-index: 10;

    overflow: hidden;

    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    .toggle__btn {
        background-color: transparent;
        border: none;

        .toggle {
            width: 28px;
            height: 28px;
            object-fit: contain;
        }
    }

    .sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100vh;

        background-color: #e1e1e1;
        color: black;
        transition: right 250ms ease;

        img.iso {
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: 48px;
            height: auto;

            content: url("../../public/favicon.png");
        }

        .close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 32px;
            height: 32px;
        }

        &.open {
            right: 0%;
        }
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.anchor {
    text-decoration: none;
    color: black;

    &:hover {
        color: inherit;
    }
}